<template>
    <div>
        <b-form-select               
            v-model="selected"
            :options="[
                {
                text: 'woreczek foliowy',
                value: 'woreczek foliowy'
                },
                {
                text: 'koperta depozytowa',
                value: 'koperta depozytowa'
                },
                {
                text: 'inne',
                value: 'inne'
                }
            ]"
        ></b-form-select>
        <b-form-input
            v-if="![
                    'woreczek foliowy',
                    'koperta depozytowa'
                ].includes(this.selected)"
            v-model="inputValue"
        ></b-form-input>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputValue: "",
            selected: ""
        }
    },
    props: ["value"],
    methods: {
        handleInput() {
            this.$emit('input', this.inputValue);
        },
    },
    watch: {
        value: {
            handler() {
                if ( ![
                    "woreczek foliowy",
                    "koperta depozytowa"
                ].includes(this.value) )
                    this.selected = "inne";
                else
                    this.selected = this.value;

                this.inputValue = this.value;
            },
            immediate: true
        },
        selected() {
            if ( [
                    "woreczek foliowy",
                    "koperta depozytowa"
                ].includes(this.selected) )
                this.inputValue = this.selected;
            else
                this.inputValue = "";
        },
        inputValue() {
            this.handleInput();
        }
    }
}
</script>
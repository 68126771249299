<template>
  <div>
    <!--begin::Customer-->
    <div class="row">
      <div class="col-xxl-12 order-1 order-xxl-1">
        <template>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="primary"
                  >Płatnik & Zleceniobiorca</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-1"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-group deck>
                    <b-card
                      title="Płatnik"
                      header-tag="header"
                      footer-tag="footer"
                    >
                      <template v-slot:header> </template>

                      <div class="row">
                        <div class="col-6">
                          <p>NIP: {{ client.nip }}<br>{{ client.name }}<br>{{ client.street }} {{ client.houseNumber }}<br>{{ client.postalCode }} {{ client.city }}</p>
                        </div>
                        <div class="col-6">
                          <b-form-group label="Zewnętrzny płatnik ( zdefiniowany w panelu klienta )">
                            <b-form-radio-group
                              id="usePayerCheck"
                              v-model="form.questionnaire.usePayer"
                              :options="[
                                {
                                  text: 'Nie',
                                  value: 'no',
                                },
                                {
                                  text: 'Tak',
                                  value: 'yes',
                                }
                              ]"
                              name="usePayerCheck"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group label="Ferma" label-for="clientFerm">
                            <b-form-select
                              id="selectedFerm"
                              v-model="form.selectedFerm"
                              :options="clientFermsList"
                              @change="validateInput(form.selectedFerm, 'selectedFerm')"
                            ></b-form-select>
                            <span style="color: red; font-weight: bold; margin: 0; padding: 5px 5px 0;" v-if="this.requiredError.includes('selectedFerm')">Pole wymagane dla matrycy urzędowej!</span>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group label="Nazwa" label-for="clientName">
                            <b-form-input
                              id="clientName"
                              placeholder="Podaj nazwę"
                              v-model="form.payer.name"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-4">
                          <b-form-group
                            label="Kod pocztowy"
                            label-for="clientPostalCode"
                          >
                            <b-form-input
                              id="clientPostalCode"
                              placeholder="Podaj kod pocztowy"
                              v-model="form.payer.postalCode"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-8">
                          <b-form-group label="Miasto" label-for="clientCity">
                            <b-form-input
                              id="clientCity"
                              placeholder="Podaj miasto"
                              v-model="form.payer.city"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-8">
                          <b-form-group label="Ulica" label-for="clientStreet">
                            <b-form-input
                              id="clientStreet"
                              placeholder="Podaj ulicę"
                              v-model="form.payer.street"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-4">
                          <b-form-group
                            label="Numer domu"
                            label-for="clientHouseNumber"
                          >
                            <b-form-input
                              id="clientHouseNumber"
                              placeholder="Podaj numer domu"
                              v-model="form.payer.houseNumber"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group label="NIP" label-for="clientNip">
                            <b-form-input
                              id="clientNip"
                              placeholder="Podaj nip"
                              v-model="form.payer.nip"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group label="Telefon" label-for="clientPhone">
                            <b-form-input
                              id="clientPhone"
                              placeholder="Podaj Telefon"
                              v-model="form.payer.phone"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group label="E-mail" label-for="clientEmail">
                            <b-form-input
                              id="clientEmail"
                              placeholder="Podaj e-mail"
                              v-model="form.payer.email"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group
                            label="Osoba kontaktowa"
                            label-for="clientContactPerson"
                          >
                            <b-form-input
                              id="clientContactPerson"
                              placeholder="Podaj osobe kontaktową"
                              v-model="form.payer.contactPerson"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-card>

                    <b-card
                    title="Adresy email do wysyłki wyników"
                    >
                      <div class="row">
                        <div class="col-12">
                          <p>Wybierz adres(y) email klienta na które zostaną rozesłane wyniki badania. Możesz również dodać adresy spoza listy</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <b-card-text>
                            Wybierz adresy email klienta
                          </b-card-text>
                          <treeselect
                            key="basic"
                            :value-consists-of="'ALL'"
                            :multiple="true"
                            :clearable="true"
                            :searchable="true"
                            :disable-branch-nodes="true"
                            instanceId="basic"
                            :options="availableReceiverEmails"
                            placeholder="Wybierz adresy email..."
                            @input="receiversUpdateValue"
                            :value="receiversGetValue('basic')"
                          />
                        </div>
                        <div class="col-6">
                          <b-form-group
                            label="Wprowadź dodatkowy adres email"
                          >
                            <b-form-input v-model="tmpReceiver"></b-form-input>
                            <b-button @click.prevent="addReceiverEmail" variant="primary" class="mt-3 mb-4">
                              Dodaj
                            </b-button>
                          </b-form-group>

                          <div v-if="form.receiverEmails.additional.length > 0">
                            <p>Dodatkowe adresy email</p>
                            <ul>
                              <li v-for="(email, index) in form.receiverEmails.additional" v-bind:key="'receiver_email-'+index">
                                {{ email }} &nbsp;&nbsp;
                                <b-button @click.prevent="removeReceiverEmail(email)" variant="danger" size="sm" class="mt-3 mb-4">
                                  Usuń
                                </b-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </b-card>

                    <!-- <b-card
                      title="Zleceniobiorca"
                      header-tag="header"
                      footer-tag="footer"
                    >
                      <template v-slot:header> </template>

                      <div class="row">
                        <div class="col-12">
                          <b-form-group label="Nazwa" label-for="mandatoryName">
                            <b-form-input
                              disabled
                              id="mandatoryName"
                              placeholder="Podaj nazwę"
                              v-model="form.mandatory.name"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-4">
                          <b-form-group
                            label="Kod pocztowy"
                            label-for="mandatoryPostalCode"
                          >
                            <b-form-input
                              disabled
                              id="mandatoryPostalCode"
                              placeholder="Podaj kod pocztowy"
                              v-model="form.mandatory.postalCode"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-8">
                          <b-form-group
                            label="Miasto"
                            label-for="mandatoryCity"
                          >
                            <b-form-input
                              disabled
                              id="mandatoryCity"
                              placeholder="Podaj miasto"
                              v-model="form.mandatory.city"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-8">
                          <b-form-group
                            label="Ulica"
                            label-for="mandatoryStreet"
                          >
                            <b-form-input
                              disabled
                              id="mandatoryStreet"
                              placeholder="Podaj ulicę"
                              v-model="form.mandatory.street"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-4">
                          <b-form-group
                            label="Numer domu"
                            label-for="mandatoryHouseNumber"
                          >
                            <b-form-input
                              disabled
                              id="mandatoryHouseNumber"
                              placeholder="Podaj numer domu"
                              v-model="form.mandatory.houseNumber"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-card> -->
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="primary"
                  >Informacje dotyczące badań</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-group deck>
                    <b-card title="" header-tag="header" footer-tag="footer">
                      <template v-slot:header> </template>

                      <div class="row">
                        <div class="col-6">
                          <b-form-group
                            label="Matryca"
                            label-for="matrixFilter"
                          >
                            <b-form-select
                              id="qMatrix"
                              v-model="form.questionnaire.matrix"
                              :options="matrixList"
                              @change="validateInput(form.questionnaire.matrix, 'qMatrix')"
                            ></b-form-select>
                            <span style="color: red; font-weight: bold; margin: 0; padding: 5px 5px 0;" v-if="this.requiredError.includes('qMatrix')">Pole wymagane!</span>
                          </b-form-group>

                          <b-form-group
                            v-if="form.questionnaire.matrix == 'inne'"
                            label="Wprowadź nazwę użytej matrycy"
                          >
                            <b-form-input v-model="form.questionnaire.matrixOther"></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null"
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Opis próbki"
                            label-for="qDescription"
                          >
                            <b-form-input
                              id="qDescription"
                              placeholder="Podaj opis próbki"
                              v-model="form.questionnaire.description"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null"
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Liczba próbek"
                            label-for="qSampleCount"
                          >
                            <b-form-input
                              id="qSampleCount"
                              :type="'number'"
                              min="0"
                              placeholder="Podaj ilość próbek"
                              v-model="form.questionnaire.sampleCount"
                              @change="validateInput(form.questionnaire.sampleCount, 'qSampleCount', true)"
                            ></b-form-input>
                            <span style="color: red; font-weight: bold; margin: 0; padding: 5px 5px 0;" v-if="this.requiredError.includes('qSampleCount')">Pole wymagane!</span>
                          </b-form-group>
                        </div>
                      </div>

                      <div 
                        class="row"
                        v-if="
                          form.questionnaire.matrix == 'urzedowe'
                        "
                      >
                      <!-- form.questionnaire.matrix == 'zywnosc' ||
                          form.questionnaire.matrix == 'woda' || -->
                        <div class="col-12">
                          <b-form-group
                            label="Rodzaj próbki"
                          >
                            <!-- <b-form-input 
                              placeholder="Rodzaj próbki"
                              v-model="form.questionnaire.sampleType"
                            /> -->
                            <b-form-select
                              id="sampleType"
                              v-model="form.questionnaire.sampleType"
                              :options="sampleList"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          (form.questionnaire.matrix === 'serologia' ||
                            form.questionnaire.matrix === 'weterynaria')
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Rodzaj materiału"
                            label-for="qType"
                          >
                            <b-form-input
                              id="qType"
                              placeholder="Rodzaj materiału"
                              v-model="form.questionnaire.type"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null"
                      >
                        <div class="col-xl-4 col-12">
                          <b-form-group
                            label="Data pobrania próbek"
                            label-for="qSamplingDate"
                          >
                            <b-form-datepicker
                            reset-button
                            type="date"
                            placeholder="dd.mm.rrrr"
                            size="xs"
                            :start-weekday="1"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                              id="qSamplingDate"
                              v-model="form.questionnaire.samplingDate"
                            >
                            </b-form-datepicker>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria'
                        "
                      >
                        <div class="col-xl-4 col-12">
                          <b-form-group
                            label="Godzina pobrania próbek"
                            label-for="qSamplingTime"
                          >
                            <b-input
                              placeholder="Wybierz godzinę"
                              size="md"
                              id="qSamplingTime"
                              v-model="form.questionnaire.samplingTime"
                              locale="de"
                              now-button
                              @input="formatTime('form.questionnaire.samplingTime')"
                            />
                           <!--  <b-form-timepicker
                              v-model="form.questionnaire.samplingTime"
                              button-only
                              right
                              locale="en"
                              id="qSamplingTime"
                              aria-controls="example-input"
                            ></b-form-timepicker> -->
                          </b-form-group>
                          <!--                          <b-input-group class="mb-3">-->
                          <!--                            <b-form-input-->
                          <!--                              id="qSamplingTime"-->
                          <!--                              v-model="form.questionnaire.samplingTime"-->
                          <!--                              type="text"-->
                          <!--                              placeholder="HH:mm:ss"-->
                          <!--                            ></b-form-input>-->
                          <!--                            <b-input-group-append>-->
                          <!--                              <b-form-timepicker-->
                          <!--                                v-model="form.questionnaire.samplingTime"-->
                          <!--                                button-only-->
                          <!--                                right-->
                          <!--                                show-seconds-->
                          <!--                                locale="en"-->
                          <!--                                aria-controls="example-input"-->
                          <!--                              ></b-form-timepicker>-->
                          <!--                            </b-input-group-append>-->
                          <!--                          </b-input-group>-->
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'woda' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-xl-4 col-12">
                          <b-form-group
                            label="Data produkcji"
                            label-for="qProductionDate"
                          >
                            <b-form-datepicker
                              reset-button
                              type="date"
                              placeholder="dd.mm.rrrr"
                              size="xs"
                              :start-weekday="1"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              id="qProductionDate"
                              v-model="form.questionnaire.productionDate"
                            >
                            </b-form-datepicker>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'woda'  &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-xl-4 col-12">
                          <b-form-group
                            label="Data waznosci"
                            label-for="qExpirationDate"
                          >
                            <b-form-datepicker
                              reset-button
                              type="date"
                              placeholder="dd.mm.rrrr"
                              size="md"
                              :start-weekday="1"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              id="qExpirationDate"
                              v-model="form.questionnaire.expirationDate"
                            >
                            </b-form-datepicker>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null"
                      >
                        <div class="col-5">
                          <b-form-group
                            label="Pobierający próbkę"
                            label-for="qSampleCollector"
                          >
                            <b-form-input
                              id="qSampleCollector"
                              placeholder="Imię i nazwisko osoby pobierającej próbkę"
                              v-model="form.questionnaire.sampleCollector"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null && form.questionnaire.matrix == 'urzedowe'"
                      >
                        <div class="col-5">
                          <b-form-group
                            label="Pobierający próbkę"
                            label-for="qSampleCollectorRole"
                          >
                            <b-form-select 
                              v-model="form.questionnaire.sampleCollectorRole"
                              :options="[
                                {
                                  value: 'owner',
                                  text: 'Właściciel',
                                },
                                {
                                  value: 'representative',
                                  text: 'Przedstawiciel zleceniodawcy',
                                },
                                {
                                  value: 'doc',
                                  text: 'Urzędowy lekarz weterynarii',
                                }
                              ]"
                            />
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null && form.questionnaire.matrix == 'urzedowe'"
                      >
                        <div class="col-5">
                          <b-form-group
                            label="Cel badania"
                            label-for="qTestReason"
                          >
                            <b-form-select 
                              v-model="form.questionnaire.qTestReason"
                              :options="[
                                {
                                  value: 'owner',
                                  text: 'Badanie z inicjatywy hodowcy',
                                },
                                {
                                  value: 'official',
                                  text: 'Badanie urzędowe',
                                },
                                {
                                  value: 'confirm',
                                  text: 'Urzędowe potwierdzające / z podejrzenia',
                                }
                              ]"
                            />
                          </b-form-group>
                        </div>
                      </div>

                      <!-- <div
                        class="row"
                        v-if="form.questionnaire.matrix !== null && form.questionnaire.matrix != 'urzedowe'"
                      >
                        <div class="col-5">
                          <b-form-group
                            label="Cel badania"
                            label-for="qTestReason"
                          >
                            <b-form-select 
                              v-model="form.questionnaire.qTestReason"
                              :options="[
                                {
                                  value: 'oversight',
                                  text: 'Przedłożenie jednostkom nadzorującym',
                                },
                                {
                                  value: 'own',
                                  text: 'Potrzeby własne',
                                }
                              ]"
                            />
                          </b-form-group>
                        </div>
                      </div> -->

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null && (
                          form.questionnaire.matrix === 'serologia' ||
                          form.questionnaire.matrix === 'urzedowe' )
                        "
                      >
                        <div class="col-12">
                          <b-form-group label="WNI" label-for="qWNI">
                            <b-form-input
                              id="qWNI"
                              placeholder="WNI"
                              v-model="form.questionnaire.wni"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix === 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Informacje dodatkowe"
                            label-for="qWNI"
                          >
                            <b-form-input
                              id="qWNI"
                              placeholder="Podaj Informacje dodatkowe (upadki, objawy chorobowe, kondycja stada, stosowane srodki) "
                              v-model="form.questionnaire.additionalInfo"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Informacje dotyczące planu i procedury pobierania próbek"
                            label-for="qSamplingPlanInfo"
                          >
                            <b-form-radio-group
                              id="qSamplingPlanInfo"
                              v-model="form.questionnaire.samplingPlanInfo"
                              :options="[
                                {
                                  text: 'próbkę pobrano zgodnie z planem / harmonogramem',
                                  value: 'planned',
                                },
                                {
                                  text: 'próbka nie objęta planem pobierania',
                                  value: 'noplan',
                                },
                                {
                                  text: 'brak informacji',
                                  value: 'noinfo',
                                },
                              ]"
                              name="qSamplingPlanInfo"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label=""
                            label-for="qSamplingPlanInfoExtra"
                          >
                            <b-form-input
                              :disabled="
                                form.questionnaire.samplingPlanInfo !==
                                'planned'
                              "
                              id="qSamplingPlanInfoExtra"
                              placeholder="próbkę pobrano zgodnie z planem / harmonogramem..."
                              v-model="form.questionnaire.samplingPlanInfoExtra"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Warunki transportu"
                            label-for="qTransportConditions"
                          >
                            <b-form-radio-group
                              id="qTransportConditions"
                              v-model="form.questionnaire.transportConditions"
                              :options="[
                                {
                                  text: 'nadzorowane, w temperaturze...',
                                  value: 'supervised',
                                },
                                {
                                  text: 'nienadzorowane',
                                  value: 'unsupervised',
                                },
                              ]"
                              name="qTransportConditions"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-4">
                          <b-form-group
                            label=""
                            label-for="qTransportConditionsSupervisedTemperature"
                          >
                            <b-input-group
                              prepend="-50"
                              append="50"
                              class="mt-3"
                            >
                              <b-form-input
                                :disabled="
                                  form.questionnaire.transportConditions !==
                                  'supervised'
                                "
                                id="qTransportConditionsSupervisedTemperature"
                                type="range"
                                min="-50"
                                max="50"
                                step="0.5"
                                v-model="
                                  form.questionnaire
                                    .transportConditionsSupervisedTemperature
                                "
                                class="py-0"
                              ></b-form-input>
                            </b-input-group>
                            <div class="mt-2">
                              Temparatura:
                              <span
                                v-if="
                                  form.questionnaire.transportConditions ===
                                  'supervised'
                                "
                              >
                                {{
                                  form.questionnaire
                                    .transportConditionsSupervisedTemperature
                                }}°C
                              </span>
                              <span v-else> nie dotyczy </span>
                            </div>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Cel badania"
                            label-for="qTestPurpose"
                          >
                            <b-form-radio-group
                              id="qTestPurpose"
                              v-model="form.questionnaire.testPurpose"
                              :options="[
                                {
                                  text: 'przedłożenie jednostkom nadzorującym (zgodność z przepisami prawa)',
                                  value: 'forSupervisingEntities',
                                },
                                {
                                  text: 'potrzeby własne',
                                  value: 'ownNeeds',
                                },
                              ]"
                              name="qTestPurpose"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          // form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Sposób przekazania faktury i raportu z badań"
                            label-for="qHandingOverType"
                          >
                            <b-form-checkbox-group
                              id="qHandingOverType"
                              v-model="form.questionnaire.handingOverType"
                              :options="[
                                {
                                  text: 'wysłać pocztą',
                                  value: 'post',
                                },
                                {
                                  text: 'odbiór osobisty',
                                  value: 'pickup',
                                },
                                {
                                  text: 'wysłac na adres e-mail',
                                  value: 'email',
                                },
                              ]"
                              name="qHandingOverType"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
<!-- 
                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          // form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label=""
                            label-for="qHandingOverTypeEmail"
                          >
                            <b-form-input
                              :disabled="
                                !lIncludes(
                                  form.questionnaire.handingOverType,
                                  'email'
                                )
                              "
                              id="qHandingOverTypeEmail"
                              placeholder="Adres e-mail..."
                              v-model="form.questionnaire.handingOverTypeEmail"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div> -->

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Niepewność wyniku badania podawana jest w sprawozdaniu, kiedy ma to znaczenia dla
miarodajności wyników badań, lub zgodności z wartościami granicznymi podanymi w przepisach prawnych. W przypadku innych potrzeb prosimy o określenie wymagań"
                            label-for="qOtherRequirements"
                          >
                            <b-form-input
                              id="qOtherRequirements"
                              placeholder="Podaj inne wymagania"
                              v-model="form.questionnaire.otherRequirements"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group
                            label="Zleceniodawca"
                            label-for="qComplianceWithRequirements"
                          >
                            <b-form-radio-group
                              id="qComplianceWithRequirements"
                              v-model="
                                form.questionnaire.complianceWithRequirements
                              "
                              :options="[
                                {
                                  text: 'oczekuje',
                                  value: 'expect',
                                },
                                {
                                  text: 'nie oczekuje',
                                  value: 'notexpect',
                                },
                              ]"
                              name="qComplianceWithRequirements"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.matrix !== 'urzedowe'
                        "
                      >
                        <div class="col-12">
                          <b-form-group 
                            label="Stwierdzenie zgodności z wymaganiami okreslonymi w rozporządzeniu / specyfikacji nr." 
                            label-for="qRegulationSpecification"
                          >
                            <b-form-input v-model="form.questionnaire.regulationSpecificationNo"></b-form-input>
                            <b-form-radio-group
                              id="qRegulationSpecification"
                              v-model="
                                form.questionnaire.regulationSpecification
                              "
                              :options="[
                                {
                                  text: 'wg zasady prostej akceptacji',
                                  value: 'simpleAcceptancePrinciple',
                                },
                                {
                                  text: 'wg zasady akceptacji z pasmem ochronnym',
                                  value: 'acceptanceRulesWithAGuardBand',
                                },
                                {
                                  text: 'wg innej zasady',
                                  value: 'principleOther',
                                },
                              ]"
                              name="qRegulationSpecification"
                            ></b-form-radio-group>
                            <b-form-input
                              v-model="form.questionnaire.regulationSpecificationOther"
                              v-if="form.questionnaire.regulationSpecification == 'principleOther'"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                        <!-- <div class="col-12">
                          <b-form-group label="Próba zbiorcza">
                            <b-form-radio-group
                              id="qTogetherTest"
                              v-model="
                                form.questionnaire.togetherTest
                              "
                              :options="[
                                {
                                  text: 'Tak',
                                  value: 'yes',
                                },
                                {
                                  text: 'Nie',
                                  value: 'no',
                                },
                              ]"
                              name="qTogetherTestField"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div> -->

                      <div
                        class="row"
                        v-if="
                          form.questionnaire.matrix !== null &&
                          form.questionnaire.matrix !== 'serologia' &&
                          form.questionnaire.matrix !== 'weterynaria' &&
                          form.questionnaire.regulationSpecification ===
                            'principleOther'
                        "
                      >
                        <div class="col-12 col-xl-6">
                          <b-form-group
                            label="wg zasady"
                            label-for="qRegulationSpecificationByPrinciple"
                          >
                            <b-form-input
                              id="qRegulationSpecificationByPrinciple"
                              placeholder="tresc innej zasady..."
                              v-model="
                                form.questionnaire
                                  .regulationSpecificationByPrinciple
                              "
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>

                      <div 
                        class="row"
                        v-if="form.questionnaire.matrix === 'urzedowe'"
                      >
                        <div class="col-12 col-xl-6">
                          <b-form-group
                            label="Dane o szczepieniach"
                          >
                           <!--  <b-form-input
                              placeholder="Dane o szczepieniach"
                              v-model="form.questionnaire.vaxData"
                            ></b-form-input> -->

                            <b-form-checkbox-group
                              id="isVaxxed"
                              v-model="form.questionnaire.vaxData"
                              :options="[
                                {
                                  text: 'Tak',
                                  value: 'true',
                                },
                                {
                                  text: 'Nie',
                                  value: 'false',
                                },
                              ]"
                              name="isVaxxed"
                            ></b-form-checkbox-group>

                            <template v-if="form.questionnaire.vaxData != 'false'">
                              <div 
                                class="row" 
                                v-for="(row, rowNum) in form.questionnaire.vaxApplied"
                                v-bind:key="'vax-' + rowNum"
                              >
                                <div class="col-12 col-xl-3">
                                  <b-form-group
                                    label="Data szczepienia"
                                  >
                                    <b-form-datepicker 
                                      :id="'vaxxDate'+rowNum"
                                      v-model="row.vaxDate"
                                      reset-button
                                      type="date"
                                      placeholder="dd.mm.rrrr"
                                      size="xs"
                                      :start-weekday="1"
                                      :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                      }"
                                    />
                                  </b-form-group>
                                </div>
                                <div class="col-12 col-xl-3">
                                  <b-form-group
                                    label="Nazwa szczepienia"
                                  >
                                    <b-form-input 
                                      :id="'vaxName'+rowNum"
                                      v-model="row.vaxName"
                                    />
                                  </b-form-group>
                                </div>
                                <div class="col-12 col-xl-3">
                                  <b-form-group
                                    label="Rodzaj"
                                  >
                                    <b-form-radio-group
                                      v-model="row.vaxType"
                                      :options="[
                                        {
                                          text: 'Żywa',
                                          value: 'active',
                                        },
                                        {
                                          text: 'Inaktywowana',
                                          value: 'inactive',
                                        },
                                      ]"
                                      :name="'vaxType'+rowNum"
                                    ></b-form-radio-group>
                                  </b-form-group>
                                </div>
                                <div class="col-12 col-xl-3">
                                  <template>
                                    <b-button 
                                      @click.prevent="() => form.questionnaire.vaxApplied.push({vaxDate: '', vaxName: '', vaxType: ''})" 
                                      variant="primary"
                                      class="mb-1"
                                    >Dodaj wiersz</b-button>
                                    <b-button 
                                      v-if="rowNum !== 0"
                                      @click.prevent="() => form.questionnaire.vaxApplied.splice(rowNum, 1)" 
                                      variant="warning"
                                    >Usuń wiersz</b-button>
                                  </template>
                                </div>
                              </div>
                            </template>
                            <!-- Change the input type here, this will also have repeater sub input option =,= -->
                          </b-form-group>
                        </div>
                      </div>

                      <div 
                        class="row"
                        v-if="form.questionnaire.matrix === 'urzedowe'"
                      >
                        <div class="col-12 col-xl-6">
                          <b-form-group
                            label="Dane o środkach przeciwdrobnoustrojowych"
                          >
                            <!-- <b-form-input
                              placeholder="Dane o środkach przeciwdrobnoustrojowych"
                              v-model="form.questionnaire.antimicrobes"
                            ></b-form-input> -->

                            <b-form-checkbox-group
                              id="isVaxxedCheck"
                              v-model="form.questionnaire.antimicrobes"
                              :options="[
                                {
                                  text: 'Tak',
                                  value: 'true',
                                },
                                {
                                  text: 'Nie',
                                  value: 'false',
                                },
                              ]"
                              name="isVaxxedCheck"
                            ></b-form-checkbox-group>

                            <template v-if="form.questionnaire.antimicrobes != 'false'">
                              <div 
                                class="row" 
                                v-for="(row, rowNum) in form.questionnaire.antimicrobesApplied"
                                v-bind:key="'vax-' + rowNum"
                              >
                                <div class="col-12 col-xl-3">
                                  <b-form-group
                                    label="Data zastosowania"
                                  >
                                    <b-form-datepicker 
                                      :id="'antiDate'+rowNum"
                                      v-model="row.date"
                                      reset-button
                                      type="date"
                                      placeholder="dd.mm.rrrr"
                                      size="xs"
                                      :start-weekday="1"
                                      :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                      }"
                                    />
                                  </b-form-group>
                                </div>
                                <div class="col-12 col-xl-6">
                                  <b-form-group
                                    label="Nazwa środka"
                                  >
                                    <b-form-input 
                                      :id="'antiName'+rowNum"
                                      v-model="row.name"
                                    />
                                  </b-form-group>
                                </div>
                                <div class="col-12 col-xl-3">
                                  <template>
                                    <b-button 
                                      @click.prevent="() => form.questionnaire.antimicrobesApplied.push({date: '', name: ''})" 
                                      variant="primary"
                                      class="mb-1"
                                    >Dodaj wiersz</b-button>
                                    <b-button 
                                      v-if="rowNum !== 0"
                                      @click.prevent="() => form.questionnaire.antimicrobesApplied.splice(rowNum, 1)" 
                                      variant="warning"
                                    >Usuń wiersz</b-button>
                                  </template>
                                </div>
                              </div>
                            </template>
                            <!-- Dokładnie ten sam schemat co przy szczepieniach -->
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row"
                        v-if="form.questionnaire.matrix === 'urzedowe'"
                      >
                       <div class="col-12 col-xl-3">
                         <b-form-group
                          label="Forma płatności"
                         >
                           <b-form-select 
                            v-model="form.questionnaire.summary.payment"
                            :options="[
                              {
                                value: 'cash',
                                text: 'Gotówka',
                              },
                              {
                                value: 'transfer',
                                text: 'Przelew',
                              }
                            ]"
                          ></b-form-select>
                         </b-form-group>
                       </div>
                       <div class="col-12 col-xl-3">
                         <b-form-group
                          label="Sposób przekazania sprawozdania"
                         >
                          <b-form-select 
                            v-model="form.questionnaire.summary.method"
                            :options="[
                              {
                                value: 'post',
                                text: 'Wysłać pocztą',
                              },
                              {
                                value: 'pickup',
                                text: 'Odbiór osobisty',
                              },
                              {
                                value: 'email',
                                text: 'Wysłać na adres email',
                              }
                            ]"
                          ></b-form-select>
                          <!-- <b-form-input 
                            v-if="form.questionnaire.summary.method == 'email'"
                            v-model="form.questionnaire.summary.methodEmail"
                          ></b-form-input> -->
                         </b-form-group>
                       </div>
                       <div class="col-12 col-xl-3">
                         <b-form-group
                          label="Do otrzymania sprawozdania z badań upoważniam:"
                         >
                           <b-form-checkbox-group 
                            v-model="form.questionnaire.summary.receiver"
                            :options="[
                            {
                              value: 'client',
                              text: 'Zleceniodawca',
                            },
                            {
                              value: 'owner',
                              text: 'Właściciel',
                            }
                          ]"
                          ></b-form-checkbox-group>
                         </b-form-group>
                       </div>   
                      </div>
                    </b-card>
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="primary"
                  >Kierunki badań (ilość zleconych próbek:
                  {{ form.questionnaire.sampleCount }})</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-button
                    @click="addSamples()"
                    class="mb-2"
                    href="#"
                    variant="primary"
                    >Utwórz zlecone próbki +</b-button
                  >

                  <div
                    class="mb-2 row"
                    v-for="(sample, idx) in form.researchLines"
                    :key="idx"
                  >
                    <div class="col-12 col-xl-12">
                      <b-card
                        :title="'PRÓBKA nr. ' + orderNo.split('/')[0] + '-' + sample.index + '/' + orderNo.split('/').splice(1, 4).join('/')"
                        header-tag="header"
                        footer-tag="footer"
                      >
                        <template v-slot:header> </template>

                        <div class="row">
                          <div class="col-12">
                            <b-form-group label="Opis próbki">
                              <b-form-input
                                placeholder="Podaj opis próbki"
                                @input="
                                  sampleDescriptionUpdateValue(idx, $event)
                                "
                                :value="sampleDescriptionGetValue(idx)"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>

                        <div class="row mt-2" v-if="idx === 0">
                          <div class="col">
                            <b-button
                              @click="copySampleDesc()"
                              class="mb-2"
                              href="#"
                              variant="primary"
                              >Kopiuj opis</b-button
                            >
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <b-card-text
                              >Wybierz kierunki badań dla próbki nr {{ sample.index }}</b-card-text
                            >
                            <treeselect
                              :key="idx"
                              :value-consists-of="'ALL'"
                              :multiple="true"
                              :clearable="true"
                              :searchable="true"
                              :disable-branch-nodes="true"
                              :instanceId="idx"
                              :options="availableResearchLines"
                              placeholder="Wybierz kierunki badań..."
                              @input="treeselectUpdateValue"
                              :value="treeselectGetValue(idx)"
                            />
                            <!--                        <template v-slot:footer>-->
                            <!--                          <b-button href="#" variant="primary"-->
                            <!--                            >Usuń próbkę</b-button-->
                            <!--                          >-->
                            <!--                        </template>-->
                          </div>

                          <div class="col-4">
                            <b-card-text>Wybierz kierunki akredytowane</b-card-text>
                            <treeselect 
                              :key="idx + 'c'"
                              :value-consists-of="'ALL'"
                              :multiple="true"
                              :clearable="true"
                              :searchable="true"
                              :disable-branch-nodes="true"
                              :instanceId="idx"
                              :options="acreditedAvailableResearchLines(idx)"
                              placeholder="Wybierz kierunki badań..."
                              @input="creditedUpdateValue"
                              :value="creditedGetValue(idx)"
                            />
                          </div>
                          <div class="col-4">
                            <b-card-text>Próbki podwójne</b-card-text>
                            <treeselect 
                              :key="idx + 'd'"
                              :value-consists-of="'ALL'"
                              :multiple="true"
                              :clearable="true"
                              :searchable="true"
                              :disable-branch-nodes="true"
                              :instanceId="idx"
                              :options="doubleAvailableResearchLines(idx)"
                              placeholder="Wybierz kierunki badań..."
                              @input="doubleUpdateValue"
                              :value="doubleGetValue(idx)"
                            />
                          </div>
                        </div>

                        <div class="row mt-2" v-if="idx === 0">
                          <div class="col">
                            <b-button
                              @click="copySampleResearch()"
                              class="mb-2"
                              href="#"
                              variant="primary"
                              >Kopiuj badania</b-button
                            >
                          </div>
                        </div>
                      </b-card>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-4 variant="primary">
                  Przegląd zlecenia
                </b-button>
              </b-card-header>

              <b-collapse
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <!--- Matryce pozostale -->
                  <template v-if="form.questionnaire.matrix != 'urzedowe'">
                    <div class="row">
                      <div class="col-xl-6">
                        <b-form-group
                          label="Data i godzina przyjęcia próbki do laboratorium"
                        >
                          <div class="row">
                            <div class="col-xl-6">
                              <b-form-datepicker
                                v-model="form.overview.generic.dateAdmitted"
                                reset-button
                                type="date"
                                placeholder="dd.mm.rrrr"
                                size="xs"
                                :start-weekday="1"
                                :date-format-options="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }"
                              />
                            </div>
                            <div class="col-xl-6">
                              <b-form-input
                                v-model="form.overview.generic.timeAdmitted"
                                @input="formatTime('form.overview.generic.timeAdmitted')"
                              />
                            </div>
                          </div>
                        </b-form-group>

                        <b-form-group label="Opakowanie bezpośrednie próbek">
                          <!-- <b-form-select
                            
                            v-model="form.overview.generic.package"
                            :options="[
                              {
                                text: 'woreczek foliowy',
                                value: 'woreczek foliowy'
                              },
                              {
                                text: 'koperta depozytowa',
                                value: 'koperta depozytowa'
                              },
                              {
                                text: 'inne',
                                value: 'inne'
                              }
                            ]"
                          ></b-form-select>
                          <b-form-input
                            
                            v-model="form.overview.generic.package"
                          /> -->
                          <opakowanie-input v-model="form.overview.generic.package"></opakowanie-input>
                        </b-form-group>

                        <b-form-group label="Temperatura [°C]">
                          <b-form-input v-model="form.overview.generic.temp" />
                        </b-form-group>

                        <b-form-group label="Masa próbki / ilość sztuk">
                          <b-form-input
                            v-model="form.overview.generic.amount"
                          />
                        </b-form-group>

                        <b-form-group label="Ocena przydatności do badań">
                          <b-form-radio
                            v-model="form.overview.generic.grade.grade"
                            name="genericGrade"
                            value="true"
                            >Bez zastrzeżeń</b-form-radio
                          >
                          <b-form-radio
                            v-model="form.overview.generic.grade.grade"
                            name="genericGrade"
                            value="false"
                            >Uwagi</b-form-radio
                          >
                          <b-form-textarea
                            v-model="form.overview.generic.grade.comment"
                            v-if="form.overview.generic.grade.grade == 'false'"
                          />
                        </b-form-group>

                        <b-form-group label="Decyzja o przyjęciu zlecenia">
                          <b-form-select
                            v-model="form.overview.generic.decision"
                            :options="[
                              {
                                value: 'admitted',
                                text: 'Przyjęto do realizacji',
                              },
                              {
                                value: 'rejected',
                                text: 'Nie przyjęto do realizacji',
                              },
                            ]"
                          />
                        </b-form-group>

                        <b-form-group label="Dodatkowe informacje">
                          <b-form-textarea
                            v-model="form.overview.generic.comment"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </template>

                  <!--- Matryca urzędowa -->
                  <template v-else>
                    <div class="row">
                      <div class="col-xl-6">
                        <h4 class="mb-4">Rejestracja próbek</h4>

                        <b-form-group label="Próbki dostarczył">
                          <b-form-input
                            v-model="form.overview.official.supplier"
                          />
                        </b-form-group>

                        <b-form-group
                          label="Data i godzina dostarczenia próbki (-ek) do laboratorium"
                        >
                          <div class="row">
                            <div class="col-xl-6">
                              <b-form-datepicker
                                v-model="form.overview.official.dateSupplied"
                                reset-button
                                type="date"
                                placeholder="dd.mm.rrrr"
                                size="xs"
                                :start-weekday="1"
                                :date-format-options="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }"
                              />
                            </div>
                            <div class="col-xl-6">
                              <b-form-input
                                v-model="form.overview.official.timeSupplied"
                                @input="formatTime('form.overview.official.timeSupplied')"
                                now-button
                                locale="pl"
                              />
                            </div>
                          </div>
                        </b-form-group>

                        <b-form-group label="Temperatura [°C]">
                          <b-form-input v-model="form.overview.official.temp" />
                        </b-form-group>

                        <b-form-group label="Czas transportu">
                          <b-form-input
                            v-model="form.overview.official.transportTime"
                          />
                        </b-form-group>

                        <b-form-group label="Opakowanie bezpośrednie próbek">
                          <b-form-input
                            v-if="form.questionnaire.matrix != 'urzedowe'"
                            v-model="form.overview.official.package"
                          />
                          <b-form-select
                            v-else
                            v-model="form.overview.official.package"
                            :options="[
                              {
                                text: 'woreczek foliowy',
                                value: 'woreczek foliowy'
                              },
                              {
                                text: 'koperta depozytowa',
                                value: 'koperta depozytowa'
                              }
                            ]"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group label="Masak próbki / ilość sztuk">
                          <b-form-input
                            v-model="form.overview.official.amount"
                          />
                        </b-form-group>

                        <b-form-group label="Ocena przydatności do badań">
                          <b-form-radio
                            v-model="form.overview.official.usageGrade.grade"
                            name="officialGrade"
                            value="true"
                            >Bez zastrzeżeń</b-form-radio
                          >
                          <b-form-radio
                            v-model="form.overview.official.usageGrade.grade"
                            name="officialGrade"
                            value="false"
                            >Uwagi</b-form-radio
                          >
                          <b-form-textarea
                            v-model="form.overview.official.usageGrade.comment"
                            v-if="
                              form.overview.official.usageGrade.grade == 'false'
                            "
                          />
                        </b-form-group>
                      </div>
                      <div class="col-xl-6">
                        <h4 class="mb-4">Przegląd zlecenia</h4>

                        <b-form-group
                          label="Data i godzina przyjęcia próbki do laboratorium"
                        >
                          <div class="row">
                            <div class="col-xl-6">
                              <b-form-datepicker
                                v-model="form.overview.official.dateAdmitted"
                                reset-button
                                type="date"
                                placeholder="dd.mm.rrrr"
                                size="xs"
                                :start-weekday="1"
                                :date-format-options="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }"
                              />
                            </div>
                            <div class="col-xl-6">
                              <b-form-input
                                v-model="form.overview.official.timeAdmitted"
                                @input="formatTime('form.overview.official.timeAdmitted')"
                              />
                            </div>
                          </div>
                        </b-form-group>

                        <b-form-group label="Ocena przydatności do badań">
                          <b-form-radio
                            v-model="form.overview.official.grade.grade"
                            name="genericGrade2"
                            value="true"
                            >Bez zastrzeżeń</b-form-radio
                          >
                          <b-form-radio
                            v-model="form.overview.official.grade.grade"
                            name="genericGrade2"
                            value="false"
                            >Uwagi</b-form-radio
                          >
                          <b-form-textarea
                            v-model="form.overview.official.grade.comment"
                            v-if="form.overview.official.grade.grade == 'false'"
                          />
                        </b-form-group>

                        <b-form-group label="Decyzja o przyjęciu zlecenia">
                          <b-form-select
                            v-model="form.overview.official.decision"
                            :options="[
                              {
                                value: 'admitted',
                                text: 'Przyjęto do realizacji',
                              },
                              {
                                value: 'rejected',
                                text: 'Nie przyjęto do realizacji',
                              },
                            ]"
                          />
                        </b-form-group>

                        <b-form-group label="Dodatkowe informacje">
                          <b-form-textarea
                            v-model="form.overview.official.comment"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </template>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </template>
        <b-button @click.prevent="saveOrder" variant="primary" class="mt-3 mb-4"
          >Dodaj wpis</b-button
        >

        <b-button @click.prevent="saveOrderDraft" v-if="this.status == 'draft'" variant="info" class="mt-3 mb-4 ml-5"
          >Zapisz szkic</b-button
        >

        <!-- <b-button v-if="this.status == 'draft'" @click.prevent="cancelOrder" variant="warning" class="mt-3 ml-5 mb-4"
          >Anuluj</b-button
        > -->
      </div>
    </div>
    <!--end::Customer-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import _ from "lodash";
import { CLIENT_LIST } from "@/core/services/store/client.module";
import { DateTime } from "luxon";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Swal from "sweetalert2";
import { FERM_LIST } from "@/core/services/store/ferm.module";
import OpakowanieInput from "@/view/content/form/OpakowanieInput"

export default {
  name: "orderPanelLab",
  props: ["clientId"],
  components: {OpakowanieInput},
  data() {
    return {
      status: "draft",
      orderNo: "",
      tmpReceiver: "",
      requiredError: ['selectedFerm', 'qMatrix'],
      matrixList: [
        { value: null, text: "Brak" },
        { value: "urzedowe", text: "Urzędowe" },
        { value: "weterynaria", text: "Weterynaria" },
        { value: "serologia", text: "Serologia" },
        { value: "pasza", text: "Pasza" },
        { value: "zywnosc", text: "Żywność" },
        { value: "woda", text: "Woda" },
        { value: "srodowisko", text: "Środowisko" },
        { value: "inne", text: "Inne" },
      ],
      sampleList: [
        {value: "sampleType1", text: "2 pary okładzin na buty (tzw. wymazy podeszwowe), liczba próbek "},
        {value: "sampleType2", text: "[chów klatkowy] próbki kału: 2 x 150 g, pakowane osobno (pulowane w 1 próbkę zbiorczą – badana jest 1 próbka analityczna o masie 25g); "},
        {value: "sampleType3", text: "[chów ściółkowy lub wybiegowy] 2 pary okładzin na buty (tzw. wymazy podeszwowe, 1 próbka zbiorcza); "},
        {value: "sampleType4", text: "[chow ściołkowy lub wybiegowy] 5 par okładzin na buty (2pary+3pary)= 2 próbki); "},
        {value: "sampleType5", text: "[chow ściołkowy lub wybiegowy] 2 probki kału o łącznej masie g (laboratorium tworzy 2 próbki analityczne, każda o masie 25 g; badane indywidualnie); ** w zależności od wielkości stada: pow. 1000 os. = 300 g"},
        {value: "sampleType6", text: "[chow ściołkowy lub wybiegowy] 1 para okładzin na buty + 1 x kurz (tampon min. 900 cm2 ), pakowane osobno (badane jako dwie odrębne probki) "},
        {value: "sampleType7", text: "chów ściółkowy lub wybiegowy] 2 pary okładzin na buty + 2x kurz (tampon min. 900 cm2 ), pakowane osobno (badane jako dwie odrębne próbki) "},
        {value: "sampleType8", text: "mekonium z 10 pojemników transportowych "},
        {value: "sampleType9", text: "narządy pobrane z padłych piskląt maksymalnie 20sztuk "},
        {value: "sampleType10", text: "wymazy powierzchniowe z 10 pojemników (próbka zbiorcza) "},
        {value: "sampleType11", text: "inne ……….. "},
      ],
      client: null,
      form: {
        receiverEmails: {
          additional: [],
          basic: []
        },
        selectedFerm: null,
        payer: {
          name: "",
          postalCode: "",
          city: "",
          street: "",
          houseNumber: "",
          nip: "",
          phone: "",
          mail: "",
          contactPerson: "",
        },
        mandatory: {
          name: "Centrum Naukowo Badawcze Ladrob",
          postalCode: "45-805",
          city: "Opole",
          street: "ul. Północna",
          houseNumber: "10",
        },
        questionnaire: {
          usePayer: 'no',
          matrix: null, //0
          matrixOther: null,
          description: "", //1
          sampleCount: 1, //2
          type: "", //3
          samplingDate: "", //4
          samplingTime: "", //5
          qTestReason: "owner",
          sampleCollectorRole: "representative",
          productionDate: "", //6
          expirationDate: "", //7
          sampleCollector: "", //8
          wni: "", //9
          additionalInfo: "", //10
          samplingPlanInfo: "noinfo", //11
          samplingPlanInfoExtra: "", //12
          transportConditions: "unsupervised",
          transportConditionsSupervisedTemperature: 0,
          testPurpose: "ownNeeds",
          handingOverType: ["email"],
          handingOverTypeEmail: "",
          otherRequirements: "",
          complianceWithRequirements: "notexpect",
          regulationSpecification: "",
          regulationSpecificationNo: "",
          regulationSpecificationOther: "",
          regulationSpecificationByPrinciple: "",
          sampleType: "sampleType1",
          vaxData: 'false',
          vaxApplied: [
            {
              vaxDate: "",
              vaxName: "",
              vaxType: ""
            }
          ],
          antimicrobes: "false",
          antimicrobesApplied: [
            {date: '', name: ''}
          ],
          summary: {
            who: "",
            payment: "transfer",
            receiver: [
              'client'
            ],
            method: "email",
            methodEmail: ""
          },
          togetherTest: "nie"
        },
        researchLines: [],
        researchLinesCredited: [],
        researchLinesDouble: [],
        overview: {
          official: {
            // Overview
            dateAdmitted: "",
            timeAdmitted: "",
            grade: {
              grade: "true",
              comment: "",
            },
            decision: "admitted",
            comment: "",

            // Registration
            supplier: "",
            dateSupplied: "",
            timeSupplied: "",
            temp: "",
            transportTime: "",
            package: "",
            amount: "",
            usageGrade: {
              grade: "true",
              comment: "",
            },
          },
          generic: {
            dateAdmitted: "",
            timeAdmitted: "",
            package: "",
            temp: "",
            amount: "",
            grade: {
              grade: "true",
              comment: "",
            },
            decision: "admitted",
            comment: "",
          },
        },
      },
      // availableResearchLines: [ 
      //   {
      //     id: "serologia",
      //     label: "Serologia",
      //     children: [
      //       {
      //         id: "serologia_ae",
      //         label: "AE",
      //         isNew: false,
      //       },
      //       {
      //         id: "serologia_cav",
      //         label: "CAV",
      //       },
      //       {
      //         id: "serologia_fadv1",
      //         label: "FAdV1",
      //       },
      //       {
      //         id: "serologia_ibd",
      //         label: "IBD",
      //       },
      //       {
      //         id: "serologia_ibv",
      //         label: "IBV",
      //       },
      //       {
      //         id: "serologia_msmg",
      //         label: "MS/MG",
      //       },
      //       {
      //         id: "serologia_ndv",
      //         label: "NDV",
      //       },
      //       {
      //         id: "serologia_ort",
      //         label: "ORT",
      //       },
      //       {
      //         id: "serologia_reo",
      //         label: "REO",
      //       },
      //     ],
      //   },
      //   {
      //     id: "weterynaria",
      //     label: "Weterynaria",
      //     children: [
      //       {
      //         id: "weterynaria_anatomo-patologiczne",
      //         label: "Badanie anatomo-patologiczne",
      //         isNew: false,
      //       },
      //       {
      //         id: "weterynaria_pobranie_materialu",
      //         label: "Pobranie materiału",
      //       },
      //       {
      //         id: "weterynaria_badanie_bakteriologiczne_4",
      //         label: "Badanie bakteriologiczne (4 kierunki)",
      //         isNew: false,
      //       },
      //       {
      //         id: "weterynaria_antybiogram",
      //         label: "Antybiogram - metoda dyfuzyjno-krążkowa",
      //       },
      //       {
      //         id: "weterynaria_badanie_mykologiczne",
      //         label: "Badanie mykologiczne",
      //         isNew: false,
      //       },
      //       {
      //         id: "weterynaria_salmonella_spp",
      //         label: "Obecność Salmonella spp.",
      //         isNew: false,
      //       },
      //       {
      //         id: "weterynaria_parazytologiczne_willysa",
      //         label:
      //           "Badanie parazytologiczne ,metoda mikroskopowa z flotacją wg Willysa",
      //         isNew: false,
      //       },
      //     ],
      //   },
      //   {
      //     id: "pasza",
      //     label: "Pasza",
      //     children: [
      //       {
      //         id: "pasza_1",
      //         label:
      //           "Obecnosć pałeczek Salmonella spp. PN-EN ISO 6579-1:2017-04 +A1:2020-09"
      //       },
      //       {
      //         id: "pasza_2",
      //         label:
      //           "Oznaczanie ogólnej liczby drobnoustrojów w 30'C PN-EN ISO 4833-1:2013-12"
      //       },
      //       {
      //         id: "pasza_3",
      //         label:
      //           "Oznaczanie liczby bakterii z rodziny Enterobacteriaceae PN-ISO 21528-2:2017-08"
      //       },
      //       {
      //         id: "pasza_4",
      //         label:
      //           "Oznaczanie liczby Clostridium perfringens PN-EN ISO 7937:2005"
      //       },
      //       {
      //         id: "pasza_5",
      //         label:
      //           "Oznaczanie liczby drożdżzy i pleśni PN-R-64791:1994"
      //       },
      //       {
      //         id: "pasza_6",
      //         label:
      //           "Obecności Listeria monocytogenes PN-EN ISO 11290-1:2017"
      //       },
      //       {
      //         id: "pasza_7",
      //         label:
      //           "Obecności Clostridium perfringens PN-EN ISO 7937:2005/ PN-R-64791:1994"
      //       },
      //       {
      //         id: "pasza_8",
      //         label:
      //           "Obecności beztlenowych laseczek przetrwalnikujących PN-R-64791:1994 Pkt.3.3.4.1"
      //       },
      //       {
      //         id: "pasza_9",
      //         label:
      //           "Oznaczenie NPL Enterobacteriaceae PN-R-64792:1997"
      //       },
      //       {
      //         id: "pasza_10",
      //         label:
      //           "Oznaczenie NPL bakterii z grupy coli PN-ISO 483:2007"
      //       },
      //       {
      //         id: "pasza_11",
      //         label:
      //           "Oznaczenie NPL Escherichia coli PN-EN ISO 16649-3:2015-07"
      //       },
      //       {
      //         id: "pasza_12",
      //         label:
      //           "Oznaczenie liczby Escherichia coli PN-ISO 16649-2:2004"
      //       },
      //       {
      //         id: "pasza_13",
      //         label:
      //           "Oznaczenie liczby Staphylococcus aureus PN-EN ISO 6888-1:2001+A1:2004+A2:2018-10"
      //       },
      //       {
      //         id: "pasza_14",
      //         label:
      //           "Inne"
      //       },
      //     ]
      //   },
      //   {
      //     id: "zywnosc",
      //     label: "Żywność",
      //     children: [
      //       {
      //         "id": "zywnosc1",
      //         "label": "Obecność pałeczek Salmonella spp. PN-EN ISO 6579-1:2017-04 + A1:2020-09"
      //       },
      //       {
      //         "id": "zywnosc2",
      //         "label": "Obecności Listeria monocytogenes PN-EN ISO 11290-1:2017"
      //       },
      //       {
      //         "id": "zywnosc3",
      //         "label": "Obecności Cronobacter spp. PN-EN ISO 22964:2017-06"
      //       },
      //       {
      //         "id": "zywnosc4",
      //         "label": "Obecność gronkowców koagulazo-dodatnich. PN-EN ISO 6888-3:2004+AC:2005"
      //       },
      //       {
      //         "id": "zywnosc5",
      //         "label": "Obecność bakterii z rodziny Enterobacteriaceae PN-ISO 21528-1:2017-08"
      //       },
      //       {
      //         "id": "zywnosc6",
      //         "label": "Oznaczanie ogólnej liczby drobnoustrojów PN-EN ISO 4833-1:2013-12"
      //       },
      //       {
      //         "id": "zywnosc7",
      //         "label": "Oznaczanie mezofilnych bakterii fermentacji mlekowej PN-ISO 15214:2002"
      //       },
      //       {
      //         "id": "zywnosc8",
      //         "label": "Oznaczanie liczby bakterii z rodziny Enterobacteriaceae PN-ISO 21528-2:2017"
      //       },
      //       {
      //         "id": "zywnosc9",
      //         "label": "Oznaczanie bakterii z grupy coli PN-ISO 4832:2007"
      //       },
      //       {
      //         "id": "zywnosc10",
      //         "label": "Oznaczanie liczby β-glukoronidazo-dodatnich Escherichia coli. PN-ISO 16649-2:2004"
      //       },
      //       {
      //         "id": "zywnosc11",
      //         "label": "Oznaczanie liczby Bacillus cereus PN-EN ISO 7932:2005"
      //       },
      //       {
      //         "id": "zywnosc12",
      //         "label": "Oznaczanie liczby drożdży i pleśni PN-ISO 21527-1:2009, PN-ISO 21527-2:2009, PN-ISO 6611:2007"
      //       },
      //       {
      //         "id": "zywnosc13",
      //         "label": "Oznaczanie liczby Listera monocytogenes PN-EN ISO 11290-2:2017-07"
      //       },
      //       {
      //         "id": "zywnosc14",
      //         "label": "Oznaczanie liczby Listeria spp. PN-EN ISO 11290-2:2017-07"
      //       },
      //       {
      //         "id": "zywnosc15",
      //         "label": "Oznaczanie liczbygronkowców koagulazo-dodatnich PN-EN ISO 6888-1:2022-03"
      //       },
      //       {
      //         "id": "zywnosc16",
      //         "label": "Inne"
      //       }
      //     ]
      //   },
      //   {
      //     id: "woda",
      //     label: "Woda",
      //     children: [
      //       {
      //         id: "woda1",
      //         label: "Ogólna liczba drobnustrojów w 22ºC PN-EN ISO 6222 A"
      //       },
      //       {
      //         id: "woda2",
      //         label: "Ogólna liczba drobnustrojów w 36ºC PN-EN ISO 6222 A"
      //       },
      //       {
      //         id: "woda3",
      //         label: "Oznaczanie liczby enterokoków kałowych PN-EN ISO 7889-2 A"
      //       },
      //       {
      //         id: "woda4",
      //         label: "Oznaczanie ilościowe Escherichia coli i bakterii grupy coli PN-EN ISO 9308-1 A"
      //       },
      //       {
      //         id: "woda5",
      //         label: "Wykrywanie i oznaczanie ilościowe Pseudomonas aeruginosa PN-EN ISO 16266 A"
      //       },
      //       {
      //         id: "woda6",
      //         label: "Oznaczanie ilościowe Clostridium perfringens PN-EN ISO 14189 A"
      //       },
      //       {
      //         id: "woda7",
      //         label: "Obecność pałeczek Salmonella spp. PN-EN ISO 6579-1:2017-04 + A1:2020-09"
      //       },
      //       {
      //         id: "woda8",
      //         label: "Inne"
      //       },
      //     ]
      //   },
      //   {
      //     id: "srodowisko",
      //     label: "Środowisko",
      //     children: [
      //       {
      //         id: "srodowisko1",
      //         label: "Obecność pałeczek Salmonella spp. (środowisko produkcji) PN-EN ISO 6579-1:2017-04 + A1:2020-09"
      //       },
      //       {
      //         id: "srodowisko2",
      //         label: "Obecność pałeczek Salmonella spp. (produkcja pierwotna) PN-EN ISO 6579-1:2017-04 + A1:2020-09"
      //       },
      //       {
      //         id: "srodowisko3",
      //         label: "Obecności Listeria monocytogenes PN-EN ISO 11290-1:2017"
      //       },
      //       {
      //         id: "srodowisko4",
      //         label: "Obecność bakterii z rodziny Enterobacteriaceae PN-ISO 21528-1:2017"
      //       },
      //       {
      //         id: "srodowisko5",
      //         label: "Oznaczanie ogólnej liczby drobnoustrojów PN-EN ISO 4833-1:2013-12"
      //       },
      //       {
      //         id: "srodowisko6",
      //         label: "Oznaczanie liczby bakterii z rodziny Enterobacteriaceae PN-ISO 21528-2:2017"
      //       },
      //       {
      //         id: "srodowisko7",
      //         label: "Oznaczanie liczby drożdży i pleśni PN-ISO 21527-2:2007"
      //       },
      //       {
      //         id: "srodowisko8",
      //         label: "Inne"
      //       }
      //     ]
      //   },
      //   {
      //     id: "urzedowe",
      //     label: "Urzędowe",
      //     children: [
      //       {
      //         id: "urzedowe1",
      //         label: "Obecność pałeczek Salmonella spp. (pojedyncza) PN-EN ISO 6579-1:2017-04 + A1:2020-09 +ISO/TR 6579-3:2014"
      //       },
      //       {
      //         id: "urzedowe2",
      //         label: "Obecność pałeczek Salmonella spp. (podwójna) PN-EN ISO 6579-1:2017-04 + A1:2020-09+ ISO/TR 6579-3:2014"
      //       }
      //     ]
      //   }
      // ],
    };
  },
  computed: {
    ...mapGetters(["clients", "ferms", "labOrders", 'researchLines']),
    availableResearchLines() {
      return this.researchLines; 
    },
    availableReceiverEmails() {
      let children = [];

      if ( undefined !== this.client )
        children = [
            ...Object
              .keys(this.client)
              .filter( key => key.toLocaleLowerCase().includes('email') )
              .map( key => this.client[key] )
              .filter( email => email.length > 3 ),
            ...this
              .fermsToShow
              .map( ferm => ferm.vetInspEmail )
              .filter(el => el.length > 0)
          ]
          .map( email => {
            return {
              id: email,
              label: email
            }
          } )

      return [{
        id: 'emails',
        label: "Adresy email",
        children
      }];
    },
    fermsToShow() {
      if (typeof this.client === "undefined" || this.client === null) {
        return [];
      }
      return _.filter(this.ferms, (f) => {
        return f.client.id == this.client.id;
      });
    },
    clientFermsList() {
      let result = [
        {
          value: null,
          text: "Brak",
        },
      ];
      this.fermsToShow.forEach((f) => {
        result.push({
          value: f.id,
          text: f.name,
        });
      });
      return result;
    },
  },
  mounted() {
    if (this.clients.length == 0) {
      this.$store.dispatch(CLIENT_LIST).then(() => {
        const c = _.find(this.clients, (o) => {
          return o.id == this.clientId;
        });
        this.client = c;
        if (this.ferms.length == 0) {
          this.$store.dispatch(FERM_LIST).then(() => {
            //zaladowanie ferm dla klienta
            this.initForm(c);
          });
        } else {
          this.initForm(c);
        }
      });
    } else {
      const c = _.find(this.clients, (o) => {
        return o.id == this.clientId;
      });
      this.client = c;
      if (this.ferms.length == 0) {
        this.$store.dispatch(FERM_LIST).then(() => {
          //zaladowanie ferm dla klienta
          this.initForm(c);
        });
      } else {
        this.initForm(c);
      }
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laboratorium - nowe zlecenie", route: "orderPanelLab" },
    ]);
    this.$store.dispatch('orderLabList');
    this.$store.dispatch('researchLinesList');
    this.form.questionnaire.samplingDate =
      DateTime.now().toFormat("yyyy-LL-dd");
    this.form.questionnaire.samplingTime = DateTime.now().toFormat("H:mm");

    let curr = new Date();
    // console.log(curr.getFullYear() + "-" + curr.getMonth() + "-" + curr.getDay())

    if ( this.form.overview.generic.timeAdmitted.length == 0 )
      this.form.overview.generic.timeAdmitted = curr.getHours() + ":" + String(curr.getMinutes()).padStart(2, "0");

    if ( this.form.overview.generic.dateAdmitted.length == 0 )
      this.form.overview.generic.dateAdmitted = curr.getFullYear() + "-" + String(curr.getMonth() + 1).padStart(2, '0') + "-" + String(curr.getDate()).padStart(2, '0')

  },
  watch: {
    "form.questionnaire.sampleCount": {
      handler() {
        this.validateInput(this.form.questionnaire.sampleCount, 'qSampleCount', true);
      },
      immediate: true
    },
    "form.questionnaire.matrix": {
      handler() {
        let curr = new Date();
        this.validateInput(this.form.questionnaire.matrix, 'qMatrix');

        if ( this.form.questionnaire.matrix == 'weterynaria' && this.form.questionnaire.type.length == 0 )
          this.form.questionnaire.type = 'narządy pobrane z piskląt';

        if ( this.form.questionnaire.matrix == 'weterynaria' && !['oversight', 'own'].includes(this.form.questionnaire.qTestReason) )
          this.form.questionnaire.qTestReason = 'own';

        if ( this.form.questionnaire.matrix == 'weterynaria' )
        {
          
          if ( this.form.overview.generic.timeAdmitted.length == 0 )
            this.form.overview.generic.timeAdmitted = curr.getHours() + ":" + String(curr.getMinutes()).padStart(2, "0");

          if ( this.form.overview.generic.dateAdmitted.length == 0 )
            this.form.overview.generic.dateAdmitted = curr.getFullYear() + "-" + String(curr.getMonth() + 1).padStart(2, '0') + "-" + String(curr.getDate()).padStart(2, '0')
          // this.form.overview.generic.dateAdmitted
        }

        if (this.form.questionnaire.matrix == 'weterynaria' )
          if ( this.form.overview.generic.package.length == 0 || !["woreczek foliowy", 'koperta depozytowa'].includes(this.form.overview.generic.package) )
            this.form.overview.generic.package = "woreczek foliowy"

        if (this.form.questionnaire.matrix == 'urzedowe' )
        {
          if ( this.form.overview.official.package.length == 0 || !["woreczek foliowy", 'koperta depozytowa'].includes(this.form.overview.official.package) )
            this.form.overview.official.package = "woreczek foliowy"

          if ( this.form.overview.official.timeAdmitted.length == 0 )
            this.form.overview.official.timeAdmitted = curr.getHours() + ":" + String(curr.getMinutes()).padStart(2, "0");

          if ( this.form.overview.official.timeSupplied.length == 0 )
            this.form.overview.official.timeSupplied = curr.getHours() + ":" + String(curr.getMinutes()).padStart(2, "0");

          if ( this.form.overview.official.dateAdmitted.length == 0 )
            this.form.overview.official.dateAdmitted = curr.getFullYear() + "-" + String(curr.getMonth() + 1).padStart(2, '0') + "-" + String(curr.getDate()).padStart(2, '0')

          if ( this.form.overview.official.dateSupplied.length == 0 )
            this.form.overview.official.dateSupplied = curr.getFullYear() + "-" + String(curr.getMonth() + 1).padStart(2, '0') + "-" + String(curr.getDate()).padStart(2, '0')
          
          if ( this.form.overview.official.supplier.length == 0 )
            this.form.overview.official.supplier = 'Przedstawiciel zleceniodawcy'
        }
      },
      immediate: true
    },
    "form.selectedFerm": {
      handler(newValue) {
        this.validateInput(this.form.selectedFerm, 'selectedFerm', true);

        let selectedFermData = _.find(this.ferms, (f) => {
          return f.id == newValue;
        });
        // console.log(selectedFermData)
        if (typeof selectedFermData !== "undefined") {
          this.form.payer.name = selectedFermData.name;
          this.form.payer.postalCode = selectedFermData.postalCode;
          this.form.payer.city = selectedFermData.city;
          this.form.payer.street = selectedFermData.street;
          this.form.payer.houseNumber = selectedFermData.houseNumber;
          this.form.questionnaire.wni = selectedFermData.wni;
        } else {
          this.form.payer.name = "";
          this.form.payer.postalCode = "";
          this.form.payer.city = "";
          this.form.payer.street = "";
          this.form.payer.houseNumber = "";
          this.form.payer.wni = "";
        }
      },
      immediate: true
    },
    "labOrders": {
      handler() {
        if ( this.$route.params.orderId )
        {
          let candidates = this.labOrders.filter(el => el.id == this.$route.params.orderId);
          if ( candidates.length > 0 )
          {
            // if (candidates[0].status == 'publish')
            // {
              if ( candidates[0].content.length != 0 )
                this.form = { ...candidates[0].content };
              
              if ( undefined == this.form.receiverEmails )
                this.form.receiverEmails = {
                  additional: [],
                  basic: []
                };
              
              if ( this.form.questionnaire.usePayer === undefined )
                this.form.questionnaire.usePayer = "no";
            // }

            if ( !this.form.researchLinesCredited || this.form.researchLinesCredited.length == 0 )
              this.form.researchLinesCredited = [
                ...this.form.researchLines.map( el => {
                  return {
                    ...el,
                    research: []
                  }
                })
              ];

            if ( !this.form.researchLinesDouble || this.form.researchLinesDouble.length == 0 )
              this.form.researchLinesDouble = [
                ...this.form.researchLines.map( el => {
                  return {
                    ...el,
                    research: []
                  }
                })
              ];
            
            this.status = candidates[0].status;
            this.orderNo = candidates[0].number;
          }
        }
      },
      immediate: true
    },
    // "form.selectedFerm": function (newValue) {
    //   let selectedFermData = _.find(this.ferms, (f) => {
    //     return f.id == newValue;
    //   });
    //   // console.log(selectedFermData)
    //   if (typeof selectedFermData !== "undefined") {
    //     this.form.payer.name = selectedFermData.name;
    //     this.form.payer.postalCode = selectedFermData.postalCode;
    //     this.form.payer.city = selectedFermData.city;
    //     this.form.payer.street = selectedFermData.street;
    //     this.form.payer.houseNumber = selectedFermData.houseNumber;
    //     this.form.questionnaire.wni = selectedFermData.wni;
    //   } else {
    //     this.form.payer.name = "";
    //     this.form.payer.postalCode = "";
    //     this.form.payer.city = "";
    //     this.form.payer.street = "";
    //     this.form.payer.houseNumber = "";
    //     this.form.payer.wni = "";
    //   }
    // },
    "form.questionnaire.samplingPlanInfo": function (newValue) {
      if (newValue !== "planned") {
        this.form.questionnaire.samplingPlanInfoExtra = "";
      }
    },
    "form.questionnaire.transportConditions": function (newValue) {
      if (newValue === "unsupervised") {
        this.form.questionnaire.transportConditionsSupervisedTemperature = null;
      } else {
        this.form.questionnaire.transportConditionsSupervisedTemperature = 0;
      }
    },
  },
  methods: {
    addReceiverEmail() {
      // console.log(this.tmpReceiver)
      if ( this.tmpReceiver.length > 0 )
        this.form.receiverEmails.additional.push(this.tmpReceiver)

      this.tmpReceiver = "";
    },
    removeReceiverEmail(email) {
      this.form.receiverEmails.additional = this.form.receiverEmails.additional.filter( em => em != email );
      this.$forceUpdate();
    },
    getObjectRef(path) {
      return path.split('.').reduce((acc, part) => acc[part], this);
    },
    formatTime(path) {
      const segments = path.split('.');
      const fieldName = segments.pop();
      const objRef = this.getObjectRef(segments.join('.'));
      
      let input = objRef[fieldName].replace(/\D/g, ''); // Remove non-digits
      if (input.length === 3 || input.length === 4) {
        objRef[fieldName] = input.substring(0, input.length - 2) + ':' + input.substring(input.length - 2);
      }
    },
    acreditedAvailableResearchLines( index ) {
      return this.availableResearchLines.filter( group => {
        if ( this.form.researchLines.length == 0 )
          return true;

        let candidates = group.children.filter( line => this.form.researchLines[index].research.includes( line.id ) )

        return candidates.length > 0
      }).map( group => {
        return {
          ...group,
          children: group.children.filter( line => this.form.researchLines[index].research.includes( line.id ) )
        }
      } );
    },
    doubleAvailableResearchLines( index ) {
      return this.availableResearchLines.filter( group => {
        if ( this.form.researchLines.length == 0 )
          return true;

        let candidates = group.children.filter( line => this.form.researchLines[index].research.includes( line.id ) )

        return candidates.length > 0
      }).map( group => {
        return {
          ...group,
          children: group.children.filter( line => this.form.researchLines[index].research.includes( line.id ) )
        }
      } );
    },
    copySampleDesc() {
      let itemToCopy = this.form.researchLines[0];
      _.forEach(this.form.researchLines, (item, index) => {
        item.desc = itemToCopy.desc;
        this.$set(this.form.researchLines, index, item);
      });
    },
    copySampleResearch() {
      let itemToCopy = this.form.researchLines[0];
      _.forEach(this.form.researchLines, (item, index) => {
        item.research = itemToCopy.research;
        this.$set(this.form.researchLines, index, item);
      });

      itemToCopy = this.form.researchLinesCredited[0];
      _.forEach(this.form.researchLinesCredited, (item, index) => {
        item.research = itemToCopy.research;
        this.$set(this.form.researchLinesCredited, index, item);
      });

      itemToCopy = this.form.researchLinesDouble[0];
      _.forEach(this.form.researchLinesDouble, (item, index) => {
        item.research = itemToCopy.research;
        this.$set(this.form.researchLinesDouble, index, item);
      });
    },
    sampleDescriptionUpdateValue(researchLineIndex, value) {
      let itemToChange = this.form.researchLines[researchLineIndex];
      itemToChange.desc = value;
      this.$set(this.form.researchLines, researchLineIndex, itemToChange);
    },
    sampleDescriptionGetValue(researchLineIndex) {
      let itemToGet = this.form.researchLines[researchLineIndex];
      if (typeof itemToGet === "undefined") {
        return [];
      }
      return itemToGet.desc;
    },
    treeselectUpdateValue(value, researchLineIndex) {
      let itemToChange = this.form.researchLines[researchLineIndex];
      itemToChange.research = value;
      this.$set(this.form.researchLines, researchLineIndex, itemToChange);
    },
    treeselectGetValue(researchLineIndex) {
      let itemToGet = this.form.researchLines[researchLineIndex];
      if (typeof itemToGet === "undefined") {
        return [];
      }
      return itemToGet.research;
    },
    receiversUpdateValue(value, researchLineIndex) {
      let itemToChange = this.form.receiverEmails[researchLineIndex];
      itemToChange = value;
      this.$set(this.form.receiverEmails, researchLineIndex, itemToChange);
    },
    receiversGetValue(researchLineIndex) {
      // console.log(researchLineIndex, this.form.receiverEmails);
      let itemToGet = this.form.receiverEmails[researchLineIndex];
      if (typeof itemToGet === "undefined") {
        return [];
      }
      return itemToGet;
    },
    creditedUpdateValue(value, researchLineIndex) {
      let itemToChange = this.form.researchLinesCredited[researchLineIndex];
      itemToChange.research = value;
      this.$set(this.form.researchLinesCredited, researchLineIndex, itemToChange);
    },
    creditedGetValue(researchLineIndex) {
      let itemToGet = this.form.researchLinesCredited[researchLineIndex];
      if (typeof itemToGet === "undefined") {
        return [];
      }
      return itemToGet.research;
    },
    doubleUpdateValue(value, researchLineIndex) {
      // console.log(researchLineIndex);
      let itemToChange = this.form.researchLinesDouble[researchLineIndex];
      itemToChange.research = value;
      this.$set(this.form.researchLinesDouble, researchLineIndex, itemToChange);
    },
    doubleGetValue(researchLineIndex) {
      let itemToGet = this.form.researchLinesDouble[researchLineIndex];
      if (typeof itemToGet === "undefined") {
        return [];
      }
      return itemToGet.research;
    },
    addSamples() {
      this.form.researchLines = [];
      for (let i = 0; i < this.form.questionnaire.sampleCount; i++) {
        this.form.researchLines.push({
          index: i + 1,
          desc: "",
          research: [],
        });

        this.form.researchLinesCredited.push({
          index: i + 1,
          desc: "",
          research: [],
        })

        this.form.researchLinesDouble.push({
          index: i + 1,
          desc: "",
          research: [],
        })
      }
    },
    lIncludes(arr, v) {
      return _.includes(arr, v);
    },
    initForm(c) {
      let emails = [
        "sales_email1", 
        "sales_email2", 
        "sales_email3",
        "lab_email1",
        "lab_email2",
        "lab_email3"
      ].map( field => c[field] ).filter(val => val.length > 0);
      let phones = [
        "sales_phone1",
        "sales_phone2",
        "sales_phone3",
        "lab_phone1",
        "lab_phone2",
        "lab_phone3"
      ].map( field => c[field] ).filter(val => val.length > 0);
      let cperson = [
        "lab_contactPersonFullName1",
        "lab_contactPersonFullName2",
        "lab_contactPersonFullName3"
      ].map( f => c[f] ).filter(v => v.length > 0);

      this.form.selectedFerm = null;
      this.form.payer.name = "";
      this.form.payer.postalCode = "";
      this.form.payer.city = "";
      this.form.payer.street = "";
      this.form.payer.houseNumber = "";
      // this.form.payer.name = c.name;
      // this.form.payer.postalCode = c.postalCode;
      // this.form.payer.city = c.city;
      // this.form.payer.street = c.street;
      // this.form.payer.houseNumber = c.houseNumber;
      this.form.payer.nip = c.nip;
      this.form.payer.phone = phones.length > 0 ? phones[0] : "";
      this.form.payer.email = emails.length > 0 ? emails[0] : "";
      this.form.questionnaire.summary.methodEmail = emails.length > 0 ? emails[0] : "";
      this.form.payer.contactPerson = cperson.length > 0 ? cperson[0] : "";

      if ( this.form.overview.official.timeSupplied.length == 0 )
        this.form.overview.official.timeSupplied = new Date().toLocaleTimeString('de-DE', { hour: "numeric", minute: "numeric"});
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    validateInput(value, key, numeric = false) {
      if ( ( key == 'selectedFerm' || key == 'qMatrix' ) && this.form.questionnaire.matrix != 'urzedowe' )
      {
        this.requiredError = [...this.requiredError.filter(el => el != 'selectedFerm')];
      }

      if ( ( !numeric && ( value === null || value.length === 0 ) ) || ( numeric && ( value === null || value < 1 ) ) ) {
        if ( !this.requiredError.includes(key) )
          this.requiredError.push(key);
      } else  {
        this.requiredError = [...this.requiredError.filter(el => el != key)];
      }
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    cancelOrder() {
      this.$store.dispatch('orderLabDelete', {id: this.$route.params.orderId})

      this.$router.push("/orderPanelLab");
    },
    saveOrder() {
      // Add form validation here, it also should be real time on the inputs themselves, so the user knows right away when input is invalid
      if ( this.requiredError.length > 0 )
      {
        Swal.fire("Błąd!", 'Proszę uzupełnić wszystkie wymagane pola formularza.', "error");
        return;
      }

      let payload = {
        form: this.form,
        clientId: this.client.id,
      };

      if ( this.$route.params.orderId )
        this.$store.dispatch("orderLabEdit", {
          id: this.$route.params.orderId,
          payload
        });
      else
        this.$store.dispatch("orderLabAdd", payload);
      this.$store.dispatch("orderLabList", payload);

      this.$router.push("/orderPanelLab");
    },
    saveOrderDraft() {
      if ( this.requiredError.length > 0 )
      {
        Swal.fire("Błąd!", 'Proszę uzupełnić wszystkie wymagane pola formularza.', "error");
        return;
      }

      let payload = {
        form: this.form,
        clientId: this.client.id,
        saveDraft: 'yes'
      };

      if ( this.$route.params.orderId )
        this.$store.dispatch("orderLabEdit", {
          id: this.$route.params.orderId,
          payload
        });
      else
        this.$store.dispatch("orderLabAdd", payload);
      this.$store.dispatch("orderLabList", payload);

      this.$router.push("/orderPanelLab");
    }
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-select',{attrs:{"options":[
            {
            text: 'woreczek foliowy',
            value: 'woreczek foliowy'
            },
            {
            text: 'koperta depozytowa',
            value: 'koperta depozytowa'
            },
            {
            text: 'inne',
            value: 'inne'
            }
        ]},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(![
                'woreczek foliowy',
                'koperta depozytowa'
            ].includes(this.selected))?_c('b-form-input',{model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }